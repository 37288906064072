import {
  CookieManager
} from './_cookieManager';

CookieManager.removeGACookies();
if (!CookieManager.hasChoiceBeenMade()) {
  window.location = '/gate.html';
}
if (CookieManager.hasChoiceBeenMade()) {
  if (CookieManager.playingWithSavedData()) {
    CookieManager.launchGA();
  }
}
